import React, { useState, useRef, useEffect } from "react"
import clienteAxios from "../../Utils/axios"
import { ToastContainer, toast } from "react-toastify"
import Button from "@material-ui/core/Button"
import { LoginTipoDocF } from "../../state/actions/Login/LoginActions"
import { useSelector, useDispatch } from "react-redux"
import TextField from "@material-ui/core/TextField"
import "../LoginComponents/login.css"
import claveunica from "../../images/claveunica.svg"
import Modal from "react-bootstrap/Modal"
import Recaptcha from "../../Utils/ReCaptcha"
import Spinner from "../templates/Spinner"

const { validate, clean, format } = require("../../Utils/ValidateRut")

const IndexPage = ({ props }) => {
  const recaptchaRef = useRef()

  const ClickClaveUnica = async () => {
    setLogClave(true)
    const respuesta = await clienteAxios.get("claveunica/url/")

    window.location.href = respuesta.data.url

    setTimeout(() => {
      setLogClave(false)
    }, 5000)
  }

  const [LogClave, setLogClave] = useState(false)
  const Logueado = useSelector(state => state.Login.Logueado)

  const [Formulario, setFormulario] = useState({
    rut: "",
    num_serie: "",
  })
  const dispatch = useDispatch()

  const [BtnIngresar, setBtnIngresar] = useState(true)

  const ValidateRut = rut => {
    if (rut !== null && rut !== "") {
      if (validate(clean(rut)) !== true) {
        setFormulario({
          ...Formulario,
          rut: "",
        })

        toast.error("El rut ingresado es inválido.")
        setBtnIngresar(true)
        return
      } else {
        setFormulario({
          ...Formulario,
          rut: format(rut),
        })
      }
    }
  }

  const onChangeForm = e => {
    e.preventDefault()

    if (e.target.name === "num_serie" && e.target.value.substring( e.target.value.length-1, e.target.value.length) !== "."){
      if(/^[\d\w]*$/gm.test(e.target.value)){
        setFormulario({
          ...Formulario,
          [e.target.name]: e.target.value,
        })
      }
    }else if (e.target.name === "rut"){
      setFormulario({
        ...Formulario,
        [e.target.name]: e.target.value,
      })
    }    

    if (Formulario.rut.length <= 0 || Formulario.num_serie.length <= 0) {
      setBtnIngresar(true)
    } else {
      setBtnIngresar(false)
    }
  }

  const SubmitData = e => {
    e.preventDefault()

    if (Formulario.rut.trim() === "" || Formulario.num_serie.trim() === "") {
      toast.error("Todos los campos son obligatorios.")
      setBtnIngresar(true)
      return
    } else {
      const LoginTipoDoc = () =>
        dispatch(LoginTipoDocF(Formulario.rut, Formulario.num_serie))
      LoginTipoDoc()
    }
  }

  const [ValidCaptcha, setValidCaptcha] = useState({})

  const [show, setShow] = useState(false)

  const onChangeRecaptcha = value => {
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      value === "undefined"
    ) {
      setValidCaptcha(false)
    } else {
      setValidCaptcha(true)
    }
  }

  const [MacheoParams, setMacheoParams] = useState(false)
  const paramsMatch = window.location.href.match(/\?.+/);
  
  useEffect(() => {
    if(paramsMatch){
      const params = new URLSearchParams(paramsMatch[0]);
      const authToken = window.atob(params.get('code'));
      const num_serie = window.atob(params.get('ser'));
      if(authToken && num_serie) {
        setMacheoParams(true)
        const LoginTipoDoc = () =>
          dispatch(LoginTipoDocF(authToken, num_serie))
        LoginTipoDoc()
      }
    }
    }, [])
  
  return (
    <>
    {MacheoParams ? <Spinner/> :
    <>
      <ToastContainer closeOnClick={true} pauseOnHover={true} />
      <div className="contenedor">
        <div className="contenedor-login shadow">
          <div className="titulo-login">
            <h1 className="py-2">Ingresa a tu portal en línea</h1>
          </div>

          <form className="form-login" onSubmit={SubmitData}>
            <TextField
              id="outlined-basic"
              onChange={onChangeForm}
              value={Formulario.rut !== "" ? format(Formulario.rut) : ""}
              onBlur={() => ValidateRut(Formulario.rut)}
              name="rut"
              className="my-2 elementos-login-ss mx-auto"
              fullWidth
              label="RUN"
              variant="outlined"
              size="small"
              autoComplete="off"
            />

            <TextField
              id="outlined-basic"
              onChange={onChangeForm}
              value={Formulario.num_serie ? Formulario.num_serie : ""}
              name="num_serie"
              className="my-2  elementos-login-ss mx-auto"
              fullWidth
              label="N° Documento / Serie"
              variant="outlined"
              size="small"
              autoComplete="off"
            />

            <p
              className="text-center my-2"
              onClick={() => setShow(!show)}
              style={{ fontSize: 12, cursor: "pointer" }}
            >
              ¿Dónde se encuentra el N° Documento / Serie?
            </p>

            <Button
              color="indigo"
              className="btn-login-ss elementos-login-ss mx-auto text-center my-2"
              disabled={BtnIngresar || Logueado}
              type="submit"
            >
              INGRESAR{" "}
              {Logueado ? (
                <div
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
            </Button>

            <p className="text-center my-2" style={{ fontSize: 12 }}>
              Puedes acceder con tu clave única
            </p>

            <Button
              color="indigo"
              className="btn-login-ss elementos-login-ss mx-auto my-2"
              onClick={() => ClickClaveUnica()}
              disabled={LogClave}
            >
              <img
                src={claveunica}
                alt="Imagen clave unica Superintendencia De Salud"
              />{" "}
              CLAVE ÚNICA
              {LogClave ? (
                <div
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
            </Button>

            <div className="mx-auto my-2">
              <a
                href="https://claveunica.gob.cl/"
                className="mx-auto"
                style={{ color: "#0064ac" }}
              >
                ¿No tienes clave única?
              </a>
            </div>
          </form>
        </div>
      </div>
      <Recaptcha {...{ onChangeRecaptcha, recaptchaRef }} />{" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex mx-auto"
          >
            Aquí puede encontrar su N° Documento / Serie.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="https://servicios.superdesalud.gob.cl/ReclamosApbWeb/img/index/ci-nueva.png"
            className="img-fluid mx-auto d-block"
            alt="Imagen Rut Superintendencia De Salud"
          />

          <img
            src="https://servicios.superdesalud.gob.cl/ReclamosApbWeb/img/index/ci-antiguo.png"
            className="img-fluid mx-auto my-2 d-block"
            alt="Imagen Rut Superintendencia De Salud"
          />
        </Modal.Body>
      </Modal>
    </>
    }
    </>
    
  )
}

export default IndexPage
